<script setup>
import { ref, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { VNodeRenderer } from '@/@layouts/components/VNodeRenderer'
import { useGenerateImageVariant } from '@core/composable/useGenerateImageVariant'
import { themeConfig } from '@themeConfig'
import authV2RegisterIllustrationBorderedDark from '@images/pages/auth-v2-register-illustration-bordered-dark.png'
import authV2RegisterIllustrationBorderedLight from '@images/pages/auth-v2-register-illustration-bordered-light.png'
import authV2RegisterIllustrationDark from '@images/pages/brain.png'
import authV2RegisterIllustrationLight from '@images/pages/brain.png'
import authV2MaskDark from '@images/pages/misc-mask-dark.png'
import authV2MaskLight from '@images/pages/misc-mask-light.png'
import GoogleSignIn from '@/components/auth/GoogleSignIn.vue'
import axios from 'axios';
import { useLoadingOverlay } from '@/composables/useLoadingOverlay'
import { useSnackbar } from "@/composables/useSnackbar";

const loading = useLoadingOverlay()
const { showSnackbar } = useSnackbar();

definePage({
  meta: {
    requiresAuth: false,
    title: "Register",
    layout: "blank",
  },
});

const router = useRouter()
const API_BASE_URL = import.meta.env.VITE_API_BASE_URL || 'http://localhost:9900/api';

const form = reactive({
  email: '',
  full_name: '',
  password: '',
  password_confirm: '',
  business_name: '',
  industry: '',
  description: '',
  privacyPolicies: false,
})

const errors = ref({})
const isPasswordVisible = ref(false)
const isConfirmPasswordVisible = ref(false)
const verifyEmailSent = ref(false)

const imageVariant = useGenerateImageVariant(authV2RegisterIllustrationLight, authV2RegisterIllustrationDark, authV2RegisterIllustrationBorderedLight, authV2RegisterIllustrationBorderedDark, true)
const authThemeMask = useGenerateImageVariant(authV2MaskLight, authV2MaskDark)


const PASSWORD_MIN_LENGTH = 8
const PASSWORD_MAX_LENGTH = 48

const validateForm = () => {
  errors.value = {}
  
  if (!form.email) {
    errors.value.email = 'Email is required'
  } else if (!/^\S+@\S+\.\S+$/.test(form.email)) {
    errors.value.email = 'Invalid email format'
  }
  
  if (!form.full_name) {
    errors.value.full_name = 'Full name is required'
  }
  
  // Enhanced password validation
  if (!form.password) {
    errors.value.password = 'Password is required'
  } else if (form.password.length < PASSWORD_MIN_LENGTH) {
    errors.value.password = `Password must be at least ${PASSWORD_MIN_LENGTH} characters long`
  } else if (form.password.length > PASSWORD_MAX_LENGTH) {
    errors.value.password = `Password cannot exceed ${PASSWORD_MAX_LENGTH} characters`
  }
  
  if (!form.password_confirm) {
    errors.value.password_confirm = 'Please confirm your password'
  } else if (form.password !== form.password_confirm) {
    errors.value.password_confirm = 'Passwords do not match'
  }
  
  if (!form.business_name) {
    errors.value.business_name = 'Business name is required'
  }
  
  if (!form.privacyPolicies) {
    errors.value.privacyPolicies = 'You must agree to the privacy policy'
  }
  
  return Object.keys(errors.value).length === 0
}




const handleSubmit = async () => {
  if (!validateForm()) return;

  try {
    loading.start('Creating account...')

    const response = await axios.post(`${API_BASE_URL}/users/register/`, {
      email: form.email,
      full_name: form.full_name.length > 30 ? form.full_name.substring(0, 30) : form.full_name,
      password: form.password,
      password_confirm: form.password_confirm,
      business_name: form.business_name,
      industry: form.industry,
      description: form.description,
    });

    if (response.status === 201) {
      verifyEmailSent.value = true;
      showSnackbar({
        message: "Account created successfully. Please verify your email.",
        color: "success",
      });
    }
  } catch (error) {
    console.error('Registration error:', error);
    
    // Handle different types of error responses
    if (error.response?.data) {
      // Handle field-specific errors
      if (typeof error.response.data === 'object') {
        errors.value = {}
        Object.entries(error.response.data).forEach(([key, value]) => {
          errors.value[key] = Array.isArray(value) ? value[0] : value
        })
      } else {
        // Handle general error message
        showSnackbar({
          message: error.response.data.error || "Registration failed. Please try again.",
          color: "error",
        });
      }
    } else {
      showSnackbar({
        message: "An unexpected error occurred. Please try again.",
        color: "error",
      });
    }
  } finally {
    loading.stop()
  }
};

const resendCooldown = ref(false)
const resendTimer = ref(60)

const resendVerificationEmail = async () => {
  if (resendCooldown.value) return

  try {
    loading.start('Resending verification email...')
    const response = await axios.post(`${API_BASE_URL}/users/resend-verification-email/`, { email: form.email })
    
    if (response.data.success) {
      showSnackbar({
        message: 'Verification email resent successfully',
        color: 'success',
      })
      startResendCooldown()
    } else {
      throw new Error(response.data.error || 'Failed to resend verification email')
    }
  } catch (error) {
    console.error('Error resending verification email:', error)
    showSnackbar({
      message: error.message || 'An error occurred while resending the verification email',
      color: 'error',
    })
  } finally {
    loading.stop()
  }
}

const startResendCooldown = () => {
  resendCooldown.value = true
  resendTimer.value = 60

  const intervalId = setInterval(() => {
    resendTimer.value--
    if (resendTimer.value <= 0) {
      clearInterval(intervalId)
      resendCooldown.value = false
    }
  }, 1000)
}

</script>

<template>
  <RouterLink to="/">
    <div class="auth-logo d-flex align-center gap-x-3">
      <VNodeRenderer :nodes="themeConfig.app.logo" />
      <h1 class="auth-title">
        {{ themeConfig.app.title }}
      </h1>
    </div>
  </RouterLink>

  <VRow no-gutters class="auth-wrapper bg-surface">
    <VCol
      md="8"
      class="d-none d-md-flex"
    >
      <div class="position-relative bg-background w-100 me-0">
        <div
          class="d-flex align-center justify-center w-100 h-100"
          style="padding-inline: 100px;"
        >
          <VImg
            max-width="500"
            :src="imageVariant"
            class="auth-illustration mt-16 mb-2"
          />
        </div>

        <img
          class="auth-footer-mask flip-in-rtl"
          :src="authThemeMask"
          alt="auth-footer-mask"
          height="280"
          width="100"
        >
      </div>
    </VCol>

    <VCol cols="12" md="4" class="auth-card-v2 d-flex align-center justify-center">
      <VCard v-if="verifyEmailSent" flat :max-width="500" class="mt-12 mt-sm-0 pa-6">
          <VCardText>
            <h4 class="text-h4 mb-1">
              Verify your email ✉️
            </h4>
            <p class="text-body-1 mb-3">
              Account activation link has been sent to your email address: <span class="font-weight-medium text-high-emphasis">{{ form.email }}</span>. Please follow the link inside to continue.
            </p>


            <div class="d-flex align-center justify-center mt-5">
              <span class="me-1">Didn't get the mail?</span>
              <a 
                href="#" 
                @click.prevent="resendVerificationEmail" 
                :class="{ 'text-disabled': resendCooldown }"
              >
                {{ resendCooldown ? `Resend in ${resendTimer}s` : 'Resend' }}
              </a>
            </div>
          </VCardText>
        </VCard>

      <VCard v-else flat :max-width="500" class="mt-12 pa-6">
        <VCardText>
          <h4 class="text-h4 mb-1"> Unlock Your Sales Potential 🚀</h4>
          <p class="mb-0">Join the AI revolution and leave manual lead gen in the dust!</p>
        </VCardText>

        <VCardText>
          <VForm @submit.prevent="handleSubmit">
            <VRow>
              <!-- Full Name -->
              <VCol cols="12">
                <AppTextField
                  v-model="form.full_name"
                  label="Full Name"
                  placeholder="John Doe"
                  :error-messages="errors.full_name"
                />
              </VCol>

              <!-- Email -->
              <VCol cols="12">
                <AppTextField
                  v-model="form.email"
                  label="Email"
                  type="email"
                  placeholder="you@company.com"
                  :error-messages="errors.email"
                  hint="Please use your business email address"
                />
              </VCol>

              <!-- Business Name -->
              <VCol cols="12">
                <AppTextField
                  v-model="form.business_name"
                  label="Business Name"
                  placeholder="Acme Inc."
                  :error-messages="errors.business_name"
                />
              </VCol>

              <!-- Password -->
              <VCol cols="12">
                <AppTextField
                  v-model="form.password"
                  label="Password"
                  placeholder="············"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  :append-inner-icon="isPasswordVisible ? 'tabler-eye-off' : 'tabler-eye'"
                  @click:append-inner="isPasswordVisible = !isPasswordVisible"
                  :error-messages="errors.password"
                />
              </VCol>

              <!-- Confirm Password -->
              <VCol cols="12">
                <AppTextField
                  v-model="form.password_confirm"
                  label="Confirm Password"
                  placeholder="············"
                  :type="isConfirmPasswordVisible ? 'text' : 'password'"
                  :append-inner-icon="isConfirmPasswordVisible ? 'tabler-eye-off' : 'tabler-eye'"
                  @click:append-inner="isConfirmPasswordVisible = !isConfirmPasswordVisible"
                  :error-messages="errors.password_confirm"
                />
              </VCol>

              <!-- Privacy Policy -->
              <VCol cols="12">
                <div class="d-flex align-center">
                  <VCheckbox v-model="form.privacyPolicies" :error-messages="errors.privacyPolicies" />
                  <VLabel>
                    I agree to the  <a href="https://www.leaduxai.io/privacy-policy" target="_blank" class="text-primary">&nbsp; privacy policy & terms</a>
                  </VLabel>
                </div>
              </VCol>

              <!-- Submit Button -->
              <VCol cols="12">
                <VBtn block type="submit" :disabled="!form.privacyPolicies">
                  Sign up
                </VBtn>
              </VCol>

              <!-- Login Link -->
              <VCol cols="12" class="text-center text-base">
                <span>Already have an account?</span>
                <RouterLink class="text-primary ms-1" to="/login">
                  Sign in instead
                </RouterLink>
              </VCol>

                <!-- auth providers -->
              <VCol
                cols="12"
                class="text-center"
              >
                <GoogleSignIn />
              </VCol>
            </VRow>
          </VForm>
        </VCardText>
      </VCard>
    </VCol>
  </VRow>
</template>

<style lang="scss">
@use "@core/scss/template/pages/page-auth.scss";
</style>
