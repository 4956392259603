<script setup>
import { ref, onMounted } from 'vue'
import { useTheme } from 'vuetify'
import ScrollToTop from '@core/components/ScrollToTop.vue'
import initCore from '@core/initCore'
import { initConfigStore, useConfigStore } from '@core/stores/config'
import axios from 'axios';
import { hexToRgb } from '@layouts/utils'

import { provideSnackbar } from '@/composables/useSnackbar'
import GlobalSnackbar from '@/components/GlobalSnackbar.vue'
import { provideLoadingOverlay } from '@/composables/useLoadingOverlay'
import GlobalLoadingOverlay from '@/components/GlobalLoadingOverlay.vue'

const { global } = useTheme()

// ℹ️ Sync current theme with initial loader theme
initCore()
initConfigStore()

const configStore = useConfigStore()
provideLoadingOverlay()
provideSnackbar()

// Version check logic
const currentVersion = "1.2.1" // Replace with app's current version
const isNewVersionAvailable = ref(false)

const checkForUpdates = async () => {
  try {
    // Check if the version was already checked
    const cachedVersion = localStorage.getItem('lastCheckedVersion')

    if (cachedVersion && cachedVersion === currentVersion) {
      console.log("No new version detected, skipping request")
      return
    }

    const API_BASE_URL = import.meta.env.VITE_API_BASE_URL || 'http://localhost:9900/api';
    const response = await axios.get(`${API_BASE_URL}/frontend/version/`); // Path to the build version file
    const data = response.data;
    const build_version = data.build_version;
    // console.log('build_version', build_version);
    if (build_version !== currentVersion) {
      isNewVersionAvailable.value = true
    }

    // Update localStorage with the latest version
    localStorage.setItem('lastCheckedVersion', build_version)
  } catch (error) {
    console.error("Failed to check for updates:", error)
  }
}

// Periodically check for updates
onMounted(() => {
  checkForUpdates() // Initial check on app load
  setInterval(checkForUpdates, 3600000) // Check every hour
})

const refreshPage = () => {
  window.location.reload();
};
</script>

<template>
  <VLocaleProvider :rtl="configStore.isAppRTL">
    <VApp :style="`--v-global-theme-primary: ${hexToRgb(global.current.value.colors.primary)}`">
      <RouterView />
      
      <GlobalSnackbar />
      <GlobalLoadingOverlay />
      <ScrollToTop />
      
      <!-- New Version Navbar -->
      <div v-if="isNewVersionAvailable" class="update-notification bg-primary">
        <p>
          A new version of LeaduxAI is available. Please <a href="#" @click.prevent="refreshPage" class="refresh-link">refresh the page</a> to get the latest updates.
        </p>
      </div>

    </VApp>
  </VLocaleProvider>
</template>

<style>
.update-notification {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 16px;
  padding: 10px 0;
  z-index: 9999;
}

.refresh-link {
  color: white;
  text-decoration: underline;
}
</style>
