<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { useRoute } from "vue-router";
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import EmailLeftSidebarContent from "@/views/apps/email/EmailLeftSidebarContent.vue";
import EmailView from "@/views/apps/email/EmailView.vue";
import { useResponsiveLeftSidebar } from "@core/composable/useResponsiveSidebar";
import { useLoadingOverlay } from "@/composables/useLoadingOverlay";
import { useSnackbar } from "@/composables/useSnackbar";
import { useApiService } from "@/common/apiService";
import TourComponent from "@/components/TourComponent.vue";

definePage({
  meta: {
    requiresAuth: true,
    title: "Unibox",
    layoutWrapperClasses: "layout-content-height-fixed",
  },
});

const api = useApiService();
const loading = useLoadingOverlay();
const { showSnackbar } = useSnackbar();

const { isLeftSidebarOpen } = useResponsiveLeftSidebar();

// Composables
const route = useRoute();

// Ref
const q = ref("");
const currentPage = ref(1);
const emailData = ref({
  count: 0,
  next: null,
  previous: null,
  total_pages: 0,
  current_page_total: 0,
  results: [],
});

const fetchEmails = async (page = 1, filters = {}) => {
  try {
    loading.start("Fetching emails...");
    const queryParams = new URLSearchParams({
      page: page.toString(),
      q: q.value,
      email_type: "filter" in route.params ? route.params.filter : "inbound",
      label: "label" in route.params ? route.params.label : undefined,
      ...filters,
    });

    console.log(" fetchEmails queryParams: ", queryParams.toString());
    const { data } = await api.get(`/unibox/emails/`, {
      params: Object.fromEntries(queryParams),
    });

    emailData.value = data;
    currentPage.value = page;
  } catch (error) {
    console.error("Error fetching emails:", error);
    showSnackbar({
      message: `Unable to fetch emails now. Please try again later.`,
      color: "error",
    });
  } finally {
    loading.stop();
  }
};

const handleActionClick = async (action, emailIds) => {
  if (!emailIds.length) return;

  for (const uuid of emailIds) {
    let updateData = {};
    let shouldRemoveEmail = false;

    // Immediately update the UI
    const emailIndex = emailData.value.results.findIndex(
      (e) => e.uuid === uuid
    );
    if (emailIndex !== -1) {
      switch (action) {
        case "star":
          emailData.value.results[emailIndex].is_starred = true;
          updateData = { is_starred: true };
          break;
        case "unstar":
          emailData.value.results[emailIndex].is_starred = false;
          updateData = { is_starred: false };
          break;
        case "trash":
          shouldRemoveEmail = true;
          updateData = { is_deleted: true };
          break;
        case "read":
          updateData = { is_seen: true };
          emailData.value.results[emailIndex].owner_seen = true;
          break;
      }

      if (shouldRemoveEmail) {
        emailData.value.results.splice(emailIndex, 1);
        emailData.value.count -= 1; // Decrease the total count
      }
    }

    // If the opened email is updated, update it as well
    if (openedEmail.value && openedEmail.value.uuid === uuid) {
      if (shouldRemoveEmail) {
        openedEmail.value = null;
      } else {
        openedEmail.value = {
          ...openedEmail.value,
          ...updateData,
        };
      }
    }

    // Send the API request
    try {
      await api.patch(`/unibox/emails/${uuid}/`, updateData);
    } catch (error) {
      console.error(`Error updating email ${uuid}:`, error);
      // Revert the UI change if the API call fails
      if (emailIndex !== -1) {
        if (shouldRemoveEmail) {
          // Add the email back to the list
          emailData.value.results.splice(emailIndex, 0, {
            ...emailData.value.results[emailIndex],
            is_deleted: false,
          });
          emailData.value.count += 1;
        } else {
          // Revert the star status
          emailData.value.results[emailIndex].is_starred =
            !emailData.value.results[emailIndex].is_starred;
        }
      }
      // Show an error message to the user
      // we can implement your own error handling mechanism here
    }
  }
};

const loadEmails = async () => {
  await fetchEmails(currentPage.value);
};

const handlePageChange = async (page) => {
  await fetchEmails(page);
};

const emails = computed(() => emailData.value.results || []);
const emailsMeta = computed(() => ({
  inbox: emailData.value.count || 0,
  star: emails.value.filter((email) => email.is_starred).length || 0,
}));

console.log("emails: ", emails.value);

// Email View
const openedEmail = ref(null);

const emailViewMeta = computed(() => {
  const returnValue = {
    hasNextEmail: false,
    hasPreviousEmail: false,
  };

  if (openedEmail.value) {
    const openedEmailIndex = emails.value.findIndex(
      (e) => e.uuid === openedEmail.value?.uuid
    );

    returnValue.hasNextEmail = !!emails.value[openedEmailIndex + 1];
    returnValue.hasPreviousEmail = !!emails.value[openedEmailIndex - 1];
  }

  return returnValue;
});

const refreshOpenedEmail = async () => {
  console.log("refreshOpenedEmail: ", openedEmail.value);
  await fetchEmails();
  if (openedEmail.value)
    openedEmail.value = emails.value.find(
      (e) => e.uuid === openedEmail.value?.uuid
    );
};

const changeOpenedEmail = (dir) => {
  if (!openedEmail.value) return;
  const openedEmailIndex = emails.value.findIndex(
    (e) => e.uuid === openedEmail.value?.uuid
  );
  const newEmailIndex =
    dir === "previous" ? openedEmailIndex - 1 : openedEmailIndex + 1;

  openedEmail.value = emails.value[newEmailIndex];
};

const openEmail = async (email) => {
  openedEmail.value = email;
  // console.log("openEmail: ", openedEmail.value);
  await handleActionClick("read", [email.uuid]);
};

const formatDateToMonthShort = (email) => {
  const dateString = ["ai_reply", "ai_initiated"].includes(
    currentEmailType.value
  )
    ? email.sent_at
    : email.created_at;

  if (!dateString) return "";

  const date = new Date(dateString);
  const now = new Date();
  const diffTime = Math.abs(now - date);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays < 1) {
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  } else if (diffDays < 7) {
    return date.toLocaleDateString([], { weekday: "short" });
  } else {
    return date.toLocaleDateString([], { month: "short", day: "numeric" });
  }
};

const currentEmailType = computed(() => route.params.filter || "inbound");
console.log("currentEmailType: ", currentEmailType.value);

const getCurrentEmailType = () => {
  if (currentEmailType.value === "deleted") return "Trash";
  if (currentEmailType.value === "starred") return "Starred";
  if (currentEmailType.value === "inbound") return "Inbound";
  if (currentEmailType.value === "human_reply") return "Human Reply";
  if (currentEmailType.value === "ai_reply") return "AI Reply";
  if (currentEmailType.value === "ai_initiated") return "Outreach";

  return "Inbound";
};


function getNameInitials(firstname, lastname) {
  const name = `${firstname} ${lastname}`;
  return name.split(' ').map(word => word[0].toUpperCase()).join('');
}

watch(
  () => route.params,
  () => {
    console.log("route.params: ", route.params);
    currentPage.value = 1;
    loadEmails();
    openedEmail.value = null;
  },
  { deep: true }
);

onMounted(() => {
  loadEmails();
});
</script>

<template>
  <TourComponent pageName="email-accounts" />

  <VLayout style="min-block-size: 100%" class="email-app-layout">
    <VNavigationDrawer
      v-model="isLeftSidebarOpen"
      absolute
      touchless
      location="start"
      :temporary="$vuetify.display.mdAndDown"
    >
      <EmailLeftSidebarContent :emails-meta="emailsMeta" />
    </VNavigationDrawer>
    <EmailView
      v-if="openedEmail"
      :email="openedEmail"
      :email-meta="emailViewMeta"
      @refresh="refreshOpenedEmail"
      @navigated="changeOpenedEmail"
      @close="openedEmail = null"
      @star="handleActionClick('star', openedEmail ? [openedEmail.uuid] : [])"
      @unstar="
        handleActionClick('unstar', openedEmail ? [openedEmail.uuid] : [])
      "
      @trash="handleActionClick('trash', openedEmail ? [openedEmail.uuid] : [])"
    />
    <VMain>
      <VCard flat class="email-content-list h-100 d-flex flex-column">
        <div class="d-flex align-center">
          <IconBtn class="d-lg-none ms-3" @click="isLeftSidebarOpen = true">
            <VIcon icon="tabler-menu-2" />
          </IconBtn>
        </div>
        <VDivider />
        <!-- 👉 Action bar -->
        <div class="py-2 px-4 d-flex align-center d-flex gap-x-1">
          <div class="v-card-item__content" style="overflow: visible">
            <div class="v-card-title py-0">{{ getCurrentEmailType() }}</div>
            <div class="v-card-subtitle">{{ emailData.count }} emails</div>
          </div>
          <VSpacer />
          <IconBtn @click="loadEmails">
            <VIcon icon="tabler-refresh" size="22" />
          </IconBtn>
        </div>
        <VDivider />
        <div class="email-list-container">
          <!-- 👉 Emails list -->
          <PerfectScrollbar
            tag="ul"
            :options="{ wheelPropagation: false }"
            class="email-list"
          >
            <li
              v-for="email in emails"
              v-show="emails?.length"
              :key="email.uuid"
              class="email-item d-flex align-center pa-4 gap-2 cursor-pointer"
              :class="{ 'email-read': email.status === 'opened' }"
              @click="openEmail(email)"
            >
              <IconBtn
                :color="email.is_starred ? 'warning' : 'default'"
                @click.stop="
                  handleActionClick(email.is_starred ? 'unstar' : 'star', [
                    email.uuid,
                  ])
                "
                v-show="!email.is_deleted"
              >
                <VIcon
                  :icon="
                    email.is_starred ? 'tabler-star-filled' : 'tabler-star'
                  "
                  size="22"
                />
              </IconBtn>

              <VAvatar size="32" v-if="email.sender_type === 'ai'">
                <VIcon
                  icon="tabler-robot-face"
                  size="22"
                />
                <!-- <VIcon v-else icon="tabler-user" size="22" /> -->
              </VAvatar>
              <VAvatar v-else 
                size="32"
                :color="email.campaign_lead.lead.photo_url ? '' : 'primary'"
                :class="email.campaign_lead.lead.photo_url ? '' : 'v-avatar-light-bg primary--text'"
                :variant="!email.campaign_lead.lead.photo_url ? 'tonal' : undefined"
              >
                <VImg
                  v-if="email.campaign_lead.lead.photo_url"
                  :src="email.campaign_lead.lead.photo_url"
                />
                <span v-else>{{ getNameInitials(email.campaign_lead.lead.first_name, email.campaign_lead.lead.last_name) }}</span>
              </VAvatar>

              <h6 class="text-h6">
                {{ email.campaign_lead.lead.first_name }}
                {{ email.campaign_lead.lead.last_name }}
              </h6>
              <span class="text-body-2 truncate">{{ email.subject }}</span>

              <VSpacer />

              <!-- 👉 Email meta -->
              <div
                class="email-meta align-center gap-2"
                :class="$vuetify.display.xs ? 'd-none' : ''"
              >
                <VIcon
                  v-if="!email.owner_seen && currentEmailType === 'inbound'"
                  icon="tabler-circle-filled"
                  size="10"
                  color="primary"
                />

                <VChip color="secondary" :label="false">
                  {{ email.campaign.name }}
                </VChip>

                <span class="text-sm text-disabled">
                  {{ formatDateToMonthShort(email) }}
                </span>
              </div>
              <!-- 👉 Email actions -->
              <div class="email-actions d-none">
                <IconBtn
                  @click.stop="handleActionClick('trash', [email.uuid])"
                  v-show="!email.is_deleted"
                >
                  <VIcon icon="tabler-trash" size="22" />
                  <VTooltip activator="parent" location="top">
                    Delete Mail
                  </VTooltip>
                </IconBtn>
              </div>
            </li>

            <li v-show="!emails.length" class="py-4 px-5 text-center">
              <span class="text-high-emphasis">No emails found.</span>
            </li>
          </PerfectScrollbar>
        </div>

        <VPagination
          v-model="currentPage"
          :length="emailData.total_pages"
          :total-visible="10"
          @update:model-value="handlePageChange"
        />
      </VCard>
    </VMain>
  </VLayout>
</template>

<style lang="scss">
@use "@styles/variables/vuetify.scss";
@use "@core/scss/base/mixins.scss";

// ℹ️ Remove border. Using variant plain cause UI issue, caret isn't align in center
.email-search {
  .v-field__outline {
    display: none;
  }

  .v-field__field {
    .v-field__input {
      font-size: 0.9375rem !important;
      line-height: 1.375rem !important;
    }
  }
}

.email-app-layout {
  border-radius: vuetify.$card-border-radius;

  @include mixins.elevation(vuetify.$card-elevation);

  $sel-email-app-layout: &;

  @at-root {
    .skin--bordered {
      @include mixins.bordered-skin($sel-email-app-layout);
    }
  }
}

.email-content-list {
  border-end-start-radius: 0;
  border-start-start-radius: 0;
}

.email-list {
  white-space: nowrap;

  .email-item {
    block-size: 4.375rem;
    transition: all 0.2s ease-in-out;
    will-change: transform, box-shadow;

    &.email-read {
      background-color: rgba(var(--v-theme-on-surface), var(--v-hover-opacity));
    }

    & + .email-item {
      border-block-start: 1px solid
        rgba(var(--v-border-color), var(--v-border-opacity));
    }
  }

  .email-item .email-meta {
    display: flex;
  }

  .email-item:hover {
    transform: translateY(-2px);

    @include mixins.elevation(4);

    // ℹ️ Don't show actions on hover on mobile & tablet devices
    @media screen and (min-width: 1280px) {
      .email-actions {
        display: block !important;
      }

      .email-meta {
        display: none;
      }
    }

    + .email-item {
      border-color: transparent;
    }

    @media screen and (max-width: 600px) {
      .email-actions {
        display: none !important;
      }
    }
  }
}

.email-compose-dialog {
  position: absolute;
  inset-block-end: 0;
  inset-inline-end: 0;
  min-inline-size: 100%;

  @media only screen and (min-width: 800px) {
    min-inline-size: 712px;
  }
}
</style>

<style lang="scss" scoped>
.email-list-container {
  height: 100%; // Adjust this value based on your layout
  max-height: 700px; // Adjust this value as needed
  overflow: hidden; // Hide overflow to ensure scrollbar appears
}

.email-list {
  height: 100%;
  overflow-y: auto; // Enable vertical scrolling

  // Ensure list takes full height of container
  display: flex;
  flex-direction: column;

  .ps__rail-y {
    // Customize scrollbar appearance if needed
    background-color: transparent;
  }
}

.email-item {
  // Your existing styles for email items
  &:not(:last-child) {
    border-bottom: 1px solid
      rgba(var(--v-border-color), var(--v-border-opacity));
  }
}

// Add any other necessary styles...
</style>
